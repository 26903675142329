@import 'styles/variables.scss';

.CodeMirror-lines {
  cursor: default;
}

.cm-s-material.CodeMirror {
  box-sizing: border-box;
  max-width: inherit;
  max-height: inherit;
  min-height: inherit;
  background-color: rgba(38, 50, 56, 0);
  font-family: "Ubuntu Mono", monospace;
  color: white;
  font-size: 18px;
  height: 100%;
}

.CodeMirror pre {
  padding-left: 5px;
  padding-right: 4px;
}

.cm-s-material .CodeMirror-gutters {
  background-color: $theme-dark;
}
.cm-s-material .CodeMirror-guttermarker,
.cm-s-material .CodeMirror-guttermarker-subtle,
.cm-s-material .CodeMirror-linenumber {
  color: white;
}

.cm-s-material .CodeMirror-guttermarker,
.cm-s-material .CodeMirror-guttermarker-subtle,
.cm-s-material .CodeMirror-linenumber {
  text-align: left;
  padding-left: 15px;
}

/* comments, e.g. // # /* */
.cm-s-material .cm-comment {
  color: white;
}

/* import for while in etc */
.cm-s-material span.cm-keyword {
  color: #deb0ff;
}

/*e.g. var *blah*, blah will be colored */
.cm-s-material span.cm-def {
  color: #47e2d5;
}

/*e.g. var *blah*, blah will be colored */
.cm-s-material span.cm-variable {
  color: #f5e83c;
}

/*for python: print, range, all built-in functions */
.cm-s-material .cm-builtin {
  color: #ffb36f;
}

/* 1 2 -11 etc */
.cm-s-material .cm-number {
  color: #f69797;
}

/* + - > < etc */
.cm-s-material .cm-operator {
  color: rgba(233, 237, 237, 1);
}

/* e.g. turtle.*Blah*(), Blah will be colored */
.cm-s-material .cm-property {
  color: #80cbae;
}
.cm-s-material .CodeMirror-cursor {
  border-left: 1px solid #f4ee94;
}

.cm-s-material .CodeMirror-cursor::after {
  position: absolute;
  margin-top: 26px;
  left: -10px;
  color: rgba(255, 255, 255, 0);
  content: "";
  width: 500px;
  height: 500px;
  background-position: left top;
  background-color: rgba(255, 255, 255, 0);
  background-size: 20px auto;
  background-repeat: no-repeat;
  background-image: url("./triangle.png");
}

/* required for safari because it places the ::after element differently than chrome/fiefox; taken from https://stackoverflow.com/questions/16348489/is-there-a-css-hack-for-safari-only-not-chrome */
@media not all and (min-resolution:.001dpcm) { 
  .cm-s-material .CodeMirror-cursor::after {
    margin-top: 2px;
  }

}
.CodeMirror-scroll {
  overflow: hidden !important;
}

.CodeMirror-lines {
  margin-bottom: 20px; /*adds space for triangle on the bottom of the editor*/
  padding-left: 3px; /*adds space for triangle on the left of the editor*/
}

/* most of the codemirror styles we can override for text
.cm-s-material .CodeMirror-activeline-background { background: rgba(0, 0, 0, 0); }
.cm-s-material .cm-operator { color: rgba(233, 237, 237, 1); }
.cm-s-material .cm-variable-2 { color: #80CBC4; }
.cm-s-material .cm-variable-3, .cm-s-material .cm-type { color: #82B1FF; }
.cm-s-material .cm-builtin { color: #DECB6B; }
.cm-s-material .cm-atom { color: #F77669; }
.cm-s-material .cm-number { color: #F77669; }
.cm-s-material .cm-def { color: rgba(233, 237, 237, 1); }
.cm-s-material .cm-string { color: #C3E88D; }
.cm-s-material .cm-string-2 { color: #80CBC4; }
.cm-s-material .cm-comment { color: #546E7A; }
.cm-s-material .cm-variable { color: #82B1FF; }
.cm-s-material .cm-tag { color: #80CBC4; }
.cm-s-material .cm-meta { color: #80CBC4; }
.cm-s-material .cm-attribute { color: #FFCB6B; }
.cm-s-material .cm-property { color: #80CBAE; }
.cm-s-material .cm-qualifier { color: #DECB6B; }
.cm-s-material .cm-variable-3, .cm-s-material .cm-type { color: #DECB6B; }
.cm-s-material .cm-tag { color: rgba(255, 83, 112, 1); }
*/
