@import 'styles/variables.scss';
.Public-wrapper {
  width: 100%;
  height: 100%;
  padding: 30px;
  background: $dark-purple;
  display: flex;
  flex-direction: column;
  color: $off-white;
  .introduce {
    display: flex;
    p {
      width: 50%;
      font-size: 24px;
    }
  }
  .editor-public-iframe {
    background: white;
    flex: 1 1 100%;
  }
}
.middle-wrapper {
  text-align: center;
  background: $dark-purple;
  color: $off-white;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  h1 {
    margin: auto;
  }
}