@import "styles/variables.scss";

.editor {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: flex-start;
  background-color: $theme-dark;
  justify-content: flex-start;
  transition: all 0.5s ease;
  position: relative;
}
.editor-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.back-button {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  margin-top: -10px;
  &:hover {
    background: none;
    border: none;
    color: white;
    font-weight: 800;
  }
}
.code-section {
  display: flex;
  overflow-y: hidden;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  max-height: 100vh;
  overflow-y: auto;
  background-color: $theme-dark;
}

.project-name-input {
  background: transparent;
  border-width: 0px 0px 2px 0px;
  color: $off-white;
  font-size: 20px;
  text-align: center;
  &:focus {
    outline: none;
  }
}
.code-section-banner {
  padding: 5px 0px;
  border-bottom: 1px white solid;
  label {
    font-size: 22px;
    color: $off-white;
    margin: 0;
    padding-top: 10px;
  }
  .toolbar-center {
    display: flex;
    .radio-selector {
      margin: 0 auto;
    }
  }
  .col-xs-12 {
    text-align: center !important;
    margin-bottom: 10px;
  }
}

.editor-expand-panel-arrow {
  color: #dddcdf;
  padding: 1rem;
  cursor: pointer;
  flex: 0 1 auto;
  width: 46px;
}
.editor-expand-panel-arrow-hidden {
  // padding: 1rem;
  // flex: 0 1 auto;
  // width: 46px;
}

.editor-language-dropdown {
  flex: 0 1 auto;
}

.editor-language-dropdown-closed-content {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
}

.editor-output {
  background-color: $theme-tertiary;
  height: 100%;
  width: 100%;
  overflow: auto;
  iframe {
    height: 100%;
  }
}

.editor-output-banner {
  height: 67px;
  padding: 5px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border-bottom: 1px white solid;
}

.editor-output-iframe {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
}

.text-editor-container {
  width: 100%;
  height: 100%;
  margin-top: 10px;
  top: 61px;
  overflow: auto;
}

.btn-language-dropdown {
  display: flex;
  align-items: center;
  font-size: 24px;
  color: white;
  border: 0px;
}

.btn-language-dropdown:hover {
  color: white;
}

.selected-line {
  background-color: rgba(255, 255, 255, 0.1);
}

.text-editor-container::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.text-editor-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.text-editor-container::-webkit-scrollbar-thumb {
  background: #37343d;
}

/* Handle on hover */
.text-editor-container::-webkit-scrollbar-thumb:hover {
  background: #2f2d33;
}
.notInteractive {
  pointer-events: none;
}


.isInteractive {
  pointer-events: auto;
}
.switch {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 48px;
  padding-top: 4px !important;
  &.small {
    width: 50px;
  }
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(248, 133, 133);
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 40px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(70px);
  -ms-transform: translateX(70px);
  transform: translateX(70px);
}
input:checked + .slider.small:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 5px;
}

.slider.round:before {
  border-radius: 20%;
}
.public-text {
  position: absolute;
  color: white;
  font-size: 1.25rem;
  left: 27px;
  line-height: 40px;
  pointer-events: none;
  &.active {
    left:10px;
  }
}