@import 'styles/variables.scss';


.sketch-thumbnail {
    display: block;
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
}

.sketch-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 200px;
    background-color: $off-white;
    border-radius: 5px;
    color: $theme-dark;
    margin: 0px 20px 20px 0;
    cursor: pointer;
    flex: 0 0 auto;
}

.sketch-box-body {
    color: inherit;
    width: 100%;
    text-align: center;
}
.sketch-box-head {
    color: inherit;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}
.sketch-box-body:hover {
    color: inherit;
    text-decoration: none;
}

.sketch-divider {
    border-top: 1px solid $theme-dark;
    width: 100%;
    margin: 0;
    padding: 0;
}

.sketch-button-divider {
    width: 1px;
    border: 0.5px solid $theme-dark;
}

.sketch-name {
    font-weight: 700;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 3px 0;
}

.sketch-icon {
    width: 85%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 5px;
    font-size: 18px;
}

.sketch-metadata{
    text-align: right;
    width: max-content;
    margin: 0 auto;
}