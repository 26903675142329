@import 'styles/variables.scss';

.login-page {
  margin: 0px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  position: relative;
}

.login-page-content {
  width: 100%;
//   height: 80vh;
  height: 100%;
  background-repeat: no-repeat;
  background: round;
  overflow-y: auto;
  box-sizing: border-box;
  background-color: #084A7A;
}

@media only screen and (max-width: 1024px) {
  .login-page-content {
    background-image: none;
  }
}

.login-form {
  padding: 0 1.5em;
  color: $off-white;
  text-align: center;
  vertical-align: middle;
  height: 100%;
  position: relative;
}

@media only screen and (max-width: 1024px) {
  .login-form-loader {
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    display: flex;
  }
}

.login-form-input-header {
  color: $off-white;
  font-size: 36px;
  margin-bottom: 1rem;
}

.login-form-input-error {
  color: $theme-error;
  font-size: 1rem;
}

.login-form-container {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 1024px) {
  .login-form-container {
    text-align: center;
    width: 100%;
  }
}
.login-modal ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */ /*changes to the placeholder text*/
  color: #c0e2fd;
}

.login-form-button {
  background-color: $theme-light;
  border-color: $theme-light;
  text-align: center;
  width: 100%;
}

.login-form-button:hover, .login-form-button:active {
  color: $theme-light !important;
  border-color: $off-white !important;
  background-color: $off-white !important;
}

.login-form-link {
  color: inherit;
  text-decoration: none;
  font-size: 1.2rem;
}

.login-form-link:hover {
  color: inherit;
  text-decoration: underline;
}
.google-idp-icon {
  width: 30px;
  margin-right: 15px;
}

.auth-container{
    align-items: center;
    height: 100%;
    display: flex;
    justify-content: center;
}
.auth-divider{
    margin-top: 15px;
}
.auth-card{
    background: #ffffffd6;
    padding: 15px;
    border-radius: 15px;
    max-width: 360px;
    width: 100%;
}
.email-submit{
    margin-block: 10px;
    width: 100%;
    border-radius: 10px;
    background: #00a4d6;
    border-color: #00a4d6
}
.login-divider{
    display: flex;
    align-items: center;
    gap: 10px;
    color: black;
    margin-top: 15px;
    
    .line{
        width: 100%;
        border-top: 2px solid #473d5b;    
    }
    
}
.loading-center{
    display: flex;
    justify-content: center;
}

.home-page{
    height: 100%;
    display: flex;
    flex-direction: column;
}
.footer{
    color: #fff;
    background-color: #0072b4;
    
    .title{
        text-align: center;
    }
    
    .feature{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 30px;
        div{
            display: flex;
            align-items: center;
            gap: 10px;
        }
        span{
            height: 10px;
            width: 10px;
            background-color: white;
            border-radius: 50%;
            display: inline-block;
        }
        h5{
            margin-bottom: 0;
        }
    }
    
    a{
        color: white;
    }
}

@media (max-width: 767px) {
    .footer{
        .feature{
            grid-template-columns: repeat(1, 1fr);
        }
    }
    .home-page{
        overflow: auto;
    }
    .login-page{
        min-height: 70vh;
    }
}
.h-100{
    height: 100%;
}
.headline{
    position: absolute;
    bottom: 3px;
    width: 100%;
    text-align: center;
    color: white;
    font-weight: 700;
}