@import 'styles/variables.scss';

.panel {
  display: flex;
  background-color: white;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: content-box;
  margin: 0;
  transition: right 0.5s ease;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    li {
      padding: 5px 5px 0 5px;
    }
    .history-link {
      cursor: pointer;
      color: $off-white;
      font-size: 15px;
      overflow: hidden;
      background-color: #252132;
      border-color: #252132;
      border-radius: 3px;
      width: 100%;
      &:focus {
        outline: none;
        border: none;
      }
      &:hover:not(.active) {
        color: #252132 !important;
        border-color: #dddcdf !important;
        background-color: #dddcdf !important;
      }
      &.active {
        color: #ffffff !important;
        font-weight: 800;
      }
    }
  }
}
