@import 'styles/variables.scss';

.Loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $dark-purple;
  width: 100%;
  height: 100%;
}

.Loading-title {
  margin: 0 0 2rem;
  font-size: 5rem;
  font-family: $font-family-heading;
  color: $off-white;
}
