@import 'styles/variables.scss';

.sketches-container {
  flex-direction: column;
  align-items: flex-start;
  background-color: $theme-dark;
  justify-content: flex-start;
  position: relative;
  transition: all 0.5s ease;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 5px 0;
  display: flex; 
}
.sketches-public-header {
  color: $off-white;
  padding: 3rem 170px 3rem 5rem;
  h2 {
    margin-bottom: 30px;
  }
}
.sketches-header-text {
  color: $off-white;
  font-weight: bold;
  padding: 1rem;
  cursor: pointer;
  flex: 0 1 auto;
  font-size: 40px;
}

.sketches-header {
  height: 67px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 5%;
}
.sketches-grid-container {
  height: 100%;
  overflow-y: auto;
  min-width: 230px;
  padding: 50px 200px 30px 80px;
  width: 100%;

}
.sketches-grid {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 80%;
  margin: auto;
}

.sketches-grid-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 20px 50px;
}

.no-sketches-container {
  margin: auto;
  text-align: center;
  color: $off-white;
  width: 100%;
  padding: 1em;
}
.sort-method {
  position: absolute;
  top: 10px;
  right: 50px;
  .sort-icon {
    font-size: 17px;
  }
  .sort-direction {
    color: white;
    font-size: 20px;
    padding-left: 7px;
    margin-bottom: 5px;
    display: block;
    background: none;
    border: none;
    &:focus {
      outline: none;
    }
  }
  &.public {
    top:3rem;
  }
}